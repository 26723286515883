import {apiUrl} from '../../../settings'

export const addFcmtPaymentUrl = () => {
    return apiUrl + '/api/addFcmtPayment'
}
export const payFcmtPaymentUrl = () => {
    return apiUrl + '/api/payFcmtPayment'
}
export const singlePaymentUrl = () => {
    return apiUrl + '/api/singlePayment'
}

export const payUrl = () => {
    return apiUrl + '/pay'
}

export const updatePaymentApi = () => {
    return apiUrl + '/updatePayment'
}
