import React, {useState, useEffect} from "react"
import "../../css/common.css"
import {Months, Years, StripePromise, notification} from "../../components/_utils";
import moment from "moment";
import {addFcmtPaymentFxn, payFcmtPaymentFxn, payFxn, updatePaymentFxn} from "./actions";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";

const initState = {
    studentId: "",
    amount: "",
    name: "",
    passportNumber: "",
    cardNumber: "",
    expiryYear: moment().format('YY'),
    expiryMonth: 1,
    cvv: "",
}
const Payment = () => {
    const stripe = useStripe();
    const elements = useElements();
    const CARD_ELEMENT_OPTIONS = {
        iconStyle: "solid",
        hidePostalCode: true,
        style: {
            base: {},
            invalid: {
                color: "#e5424d",
                ":focus": {
                    color: "#303238"
                }
            }
        }
    };
    let [state, setState] = useState(initState);
    let [isLoading, setIsLoading] = useState(false)
    let events = {
        localStateUpdate: (e) => {
            let {value, name} = e.target;
            setState({
                ...state,
                [name]: value,
            })
        },
        submitPayment: async (event) => {
            event.preventDefault();
            if (!state.studentId) {
                notification.warning({title: "Enter Student ID"})
                return
            }
            if (!state.amount) {
                notification.warning({title: "Enter Amount"})
                return
            }
            if (state.amount < 1) {
                notification.warning({title: "Enter Valid Amount"})
                return
            }
            if (!state.name) {
                notification.warning({title: "Enter Card Holder Name"})
                return
            }
            /*  if (!state.cardNumber) {
                  alert('Enter Card Number');
                  return
              }
              if (!state.expiryYear) {
                  alert('Choose Expiry Year');
                  return
              }
              if (!state.expiryMonth) {
                  alert('Choose Expiry Month');
                  return
              }
              if (!state.cvv) {
                  alert('Enter CVV Number');
                  return
              }
              if (elements == null) {
                  return;
              }*/


            setIsLoading(true)
            const card = elements.getElement(CardElement);
            if (!card) {
                return;
            }

            const result = await stripe.createToken(card);
            return
            if (result && result.error) {
                notification.warning({title: result.error.message})
                setIsLoading(false)
                return
            } else {
                let obj = {
                    cardToken: result.token,
                    ...state
                }
                let resp = await payFcmtPaymentFxn(obj)
                if (resp && resp.success) {
                    setState({
                        ...state,
                        ...initState
                    })
                    // notification.warning({title: resp.message})
                    setTimeout(() => {
                        setIsLoading(false)
                        window.location.href = `/pay/${resp.data._id}`
                    }, 1000)
                } else {
                    notification.error({title: resp.message})
                    setIsLoading(false)
                }
            }

        },
        submitPaymentFinal: async (event) => {
            event.preventDefault();
            if (!state.studentId) {
                notification.warning({title: "Enter Student ID"})
                return
            }
            if (!state.name) {
                notification.warning({title: "Enter Student Name"})
                return
            }
            if (!state.passportNumber) {
                notification.warning({title: "Enter Passport No"})
                return
            }
            if (!state.amount) {
                notification.warning({title: "Enter Amount"})
                return
            }
            if (state.amount < 1) {
                notification.warning({title: "Enter Valid Amount"})
                return
            }
            if (!state.name) {
                notification.warning({title: "Enter Card Holder Name"})
                return
            }

            setIsLoading(true)
            const card = elements.getElement(CardElement);
            if (!card) {
                return;
            }
            const result = await stripe.createToken(card);
            if (result && result.error) {
                notification.warning({title: result.error.message})
                setIsLoading(false)
                return
            } else {
                let data = await payFxn(state)
                try {
                    const cardElement = elements.getElement(CardElement);
                    const confirmPayment = await stripe.confirmCardPayment(data.clientSecret, {
                        payment_method: {card: cardElement}
                    });
                    const {paymentIntent} = confirmPayment;
                    let obj = {
                        fcmtPaymentId: data.fcmtPaymentId,
                        status: "Failed",
                        response: ""
                    }
                    if (paymentIntent) {
                        obj.status = paymentIntent.status;
                        obj.response = paymentIntent;
                    } else {
                        obj.response = confirmPayment;
                    }
                    let resp = await updatePaymentFxn(obj)
                    setState({
                        ...state,
                        ...initState
                    })
                    setTimeout(() => {
                        setIsLoading(false)
                        window.location.href = `/pay/${resp.data._id}`
                    }, 500)
                } catch (e) {
                    // notification.error({title: 'Payment failed'})
                    // setIsLoading(false)
                    setTimeout(() => {
                        setIsLoading(false)
                        window.location.href = `/pay/${data.fcmtPaymentId}`
                    }, 500)
                }
            }

        },
        submitPayment1: async (event) => {
            event.preventDefault();
            try {
                let data = await payFxn()
                const cardElement = elements.getElement(CardElement);
                const confirmPayment = await stripe.confirmCardPayment(data.clientSecret, {
                    payment_method: {card: cardElement}
                });
                const {paymentIntent} = confirmPayment;
                let obj = {
                    fcmtPaymentId: data.fcmtPaymentId,
                    status: paymentIntent.status,
                    response: paymentIntent
                }
                let resp = await updatePaymentFxn(obj)

            } catch (e) {
                alert('Payment failed');
            }
        }
    }

    return (
        <>
            {isLoading ? <div className={'loader_outer_view'}>
                <div className={'loader_inner'}>
                    <div className={'spinnerText'}>
                        Loading
                    </div>
                </div>
            </div> : null}


            <section className={'custom-container'}>
                <div className="container">
                    <div className={'card custom-card'}>
                        <div className={'logoBox'}>
                            <img src={'../images/fcmt_white_logo.png'}/>
                        </div>
                        <div className={'card-body'}>
                            <form>
                                <div className="mb-3 mt-3">
                                    <label className="form-label">
                                        Student Id <small> (*Mandatory)</small>
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Student ID"
                                           required={true}
                                           name="studentId" value={state.studentId}
                                           onChange={events.localStateUpdate}/>
                                </div>
                                <div className="mb-3 mt-3">
                                    <label className="form-label">
                                        Student Name <small> (*Mandatory)</small>
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Student Name"
                                           required={true}
                                           name="name" value={state.name}
                                           onChange={events.localStateUpdate}/>
                                </div>
                                <div className="mb-3 mt-3">
                                    <label className="form-label">
                                        Passport No <small> (*Mandatory)</small>
                                    </label>

                                    <input type="text" className="form-control" placeholder="Enter Passport No"
                                           required={true}
                                           name="passportNumber" value={state.passportNumber}
                                           onChange={events.localStateUpdate}/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Amount <small> ($CAD)</small>
                                    </label>
                                    <input type="number" className="form-control" placeholder="Enter Amount"
                                           required={true}
                                           name="amount" value={state.amount} onChange={events.localStateUpdate}/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Card Holder Name
                                    </label>
                                    <input type="text" className="form-control" placeholder="Enter Card Holder Name"
                                           required={true}
                                           name="name" value={state.name} onChange={events.localStateUpdate}/>
                                </div>

                                <div className="mb-5">
                                    <label className="form-label" style={{lineHeight: 1}}>
                                        <div>Card Number</div>
                                        <small> (International Payment Enabled - Debit/Credit Card Only)</small>
                                    </label>
                                </div>
                                <br/>
                                <div>
                                    <CardElement options={CARD_ELEMENT_OPTIONS}/>
                                </div>
                                <div className={'align_right'}>
                                    <button type="submit" className="btn btn-success btn-block"
                                            onClick={events.submitPaymentFinal}>Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const PaymentForm = () => {

    return (
        <>

            <Elements stripe={StripePromise}>
                <Payment/>
            </Elements>

        </>
    )
}
export default PaymentForm
