import React from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import "../../css/common.css"
import {Months, Years} from "../../components/_utils";
import moment from "moment";
import {payFcmtPaymentFxn} from "../Payment/actions";

const stripePromise = loadStripe('pk_test_51LNk0qDjoY4Ku4o3eQgP9CBZUXridcq8ntpZAPEfsH4kU8zavj9vDhf3Nbt3hC01PTtQBhtMVNkBiblck3cGXiHj00BduHSGGo');
const skKey = "sk_test_51LNk0qDjoY4Ku4o32rYnHr0r9UbYypq8g2EVMxECm3omDlHgu5herGJOKnDLbaDBaLBCYSVTQgBEcwsw0Qrckwdh00Tb7KYkDF";
// const stripePromise = loadStripe('pk_live_51LNk0qDjoY4Ku4o3fSgqJIgYC8Kq5Ob9RPmAAG1BIj7EfIT3cMT7UyEu5E79p5R5lIil3PQbXyHpsSI4RaPzYaMZ00Pi9bNOcf');

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const CARD_ELEMENT_OPTIONS = {
        iconStyle: "solid",
        hidePostalCode: true,
        style: {
            base: {
                iconColor: "rgb(240, 57, 122)",
                color: "rgb(240, 57, 122)",
                fontSize: "16px",
                fontFamily: '"Open Sans", sans-serif',
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#CFD7DF"
                }
            },
            invalid: {
                color: "#e5424d",
                ":focus": {
                    color: "#303238"
                }
            }
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (elements == null) {
            return;
        }

        /*const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        })
        console.log(error)
        console.log(paymentMethod)*/


        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        let obj = {
            studentId: 12345,
            amount: 50,
            name: 'rakesh',
            cardToken: result.token
        }
        console.log(result)
        console.log(obj)
        let resp = await payFcmtPaymentFxn(obj)
        console.log(resp)

    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement/>
            <button type="submit" disabled={!stripe || !elements}>
                Pay
            </button>
        </form>
    );
};

const PaymentForm = () => {

    return (
        <>


            <section className={'custom-container'}>
                <div className="container">
                    <div className={'card custom-card'}>
                        <div className={'logoBox'}>
                            <img src={'../images/fcmt_white_logo.png'}/>
                        </div>
                        <div className={'card-body'}>
                            <Elements stripe={stripePromise}>
                                <CheckoutForm/>
                            </Elements>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default PaymentForm
