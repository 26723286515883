import {addFcmtPaymentUrl, payFcmtPaymentUrl, singlePaymentUrl, payUrl, updatePaymentApi} from '../apis'
import {customAxios as axios} from '../../../request'

export const addFcmtPaymentFxn = async (valData) => {
    let {data} = await axios.post(addFcmtPaymentUrl(), valData)
    return data
}

export const payFcmtPaymentFxn = async (valData) => {
    let {data} = await axios.post(payFcmtPaymentUrl(), valData)
    return data
}
export const singlePaymentFxn = async (valData) => {
    let {data} = await axios.post(singlePaymentUrl(), valData)
    return data
}

export const payFxn = async (valData) => {
    let {data} = await axios.post(payUrl(), valData)
    return data
}

export const updatePaymentFxn = async (valData) => {
    let {data} = await axios.post(updatePaymentApi(), valData)
    return data
}
